import React from "react"
import { Link } from "gatsby"
import ScreenReaderOnly from "./ScreenReaderOnly/ScreenReaderOnly"

const GlassesImage = () => (
  <Link to={'/'} style={{background: 'none', display: 'flex'}}>
    <ScreenReaderOnly>Irlen Clinic</ScreenReaderOnly>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="50.000000pt" height="25.000000pt" viewBox="0 0 1280.000000 640.000000"
         preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
         fill="#A656E3" stroke="none">
        <path d="M9685 5409 c-409 -29 -748 -83 -1607 -254 -916 -182 -1249 -225
                 -1747 -225 -458 -1 -671 28 -1401 190 -417 92 -520 113 -695 139 -287 44 -675
                 95 -925 120 -465 48 -936 42 -1630 -20 -875 -78 -1176 -89 -1589 -63 l-94 7 6
                 -154 c17 -393 94 -919 164 -1111 38 -106 62 -140 203 -278 126 -124 128 -126
                 153 -205 14 -44 42 -143 62 -220 79 -305 225 -699 435 -1170 138 -312 188
                 -406 265 -502 370 -462 1246 -737 2144 -674 432 31 706 94 963 222 468 234
                 822 617 1173 1269 141 261 175 348 262 670 71 264 104 362 165 487 85 177 122
                 192 418 177 185 -10 188 -10 231 -39 108 -75 200 -250 294 -560 103 -341 169
                 -512 295 -770 295 -605 667 -982 1192 -1209 751 -325 1669 -322 2353 8 243
                 117 429 249 595 422 211 220 353 466 469 813 42 126 67 216 156 566 90 358
                 136 510 178 588 35 66 101 130 190 183 90 54 114 80 162 174 146 291 290 874
                 272 1107 -7 89 -38 158 -85 190 -26 17 -54 22 -150 28 -129 9 -480 0 -637 -15
                 -55 -6 -208 -13 -340 -16 -189 -5 -265 -3 -355 9 -63 9 -230 32 -370 51 -140
                 19 -322 42 -405 51 -181 20 -591 27 -770 14z m-5820 -414 c432 -51 934 -197
                 1200 -347 176 -99 309 -218 384 -342 204 -340 112 -864 -299 -1701 -141 -287
                 -259 -456 -449 -644 -308 -305 -695 -491 -1181 -568 -160 -25 -601 -25 -777 0
                 -155 22 -352 62 -459 92 -376 110 -683 378 -863 756 -63 132 -157 416 -229
                 694 -123 471 -173 1030 -115 1290 84 381 434 598 1128 699 159 24 536 64 705
                 76 85 6 173 12 195 14 96 9 638 -4 760 -19z m6035 1 c848 -76 1204 -160 1478
                 -349 240 -165 324 -383 308 -802 -15 -417 -100 -848 -266 -1347 -99 -299 -195
                 -469 -363 -645 -210 -219 -427 -335 -766 -409 -297 -64 -667 -90 -937 -65
                 -606 57 -1110 314 -1467 749 -179 220 -375 596 -544 1045 -140 374 -187 701
                 -133 926 97 403 508 663 1325 840 135 29 412 67 545 75 138 8 653 -4 820 -18z"/>
      </g>
    </svg>
  </Link>
)

export default GlassesImage;