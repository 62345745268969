/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import "fontsource-roboto"
import "../styles/styles.scss"
import Header from "./Header/Header"
import { HeaderLogoProps } from "./HeaderLogo/HeaderLogo"
import { HeaderLinksProps } from "./HeaderLinks/HeaderLinks"
import Container from "./Container/Container"
import ColorPicker from "./ColorPicker/ColorPicker"
import Footer from "./Footer/Footer"

interface LayoutProps extends React.HTMLProps<HTMLDivElement> {
  slug?: string;
}

const Layout = ({ children, slug = "/" }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMdx {
        edges {
          node {
            id
            frontmatter {
              title
              includeInHeader
              order
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const normalizeSlug = slug => {
    if (!slug?.length)
      return slug;

    if (slug.startsWith('/'))
      slug = slug.substring(1);

    if (slug.endsWith('/'))
      slug = slug.slice(0, slug.length - 1);

    return slug;
  }

  const headerLogo: HeaderLogoProps = {
    label: 'Irlen Clinic'
  }
  const headerLinks: HeaderLinksProps = {
    headerLinks: data.allMdx.edges
      .filter(x => x.node.frontmatter.includeInHeader)
      .sort((x, y) => x.node.frontmatter.order - y.node.frontmatter.order)
      .map(x => {
        return {
          label: x.node.frontmatter.title,
          slug: x.node.fields.slug,
          active: normalizeSlug(x.node.fields.slug) === normalizeSlug(slug)
        }
      })
  }

  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    const selectedColor = sessionStorage.getItem('selectedColor') || "#ffffff";
    setBackgroundColor(selectedColor);
  }, [])

  const onColorSelect = color => {
    sessionStorage.setItem('selectedColor', color);
    setBackgroundColor(color);
  }

  return (
    <>
      <div style={{backgroundColor, transition: "0.3s"}}>
        <Header headerLogo={headerLogo} headerLinks={headerLinks} />
        <Container theme="accent" textAlign={"center"}>
          <ColorPicker onColorSelect={onColorSelect} />
        </Container>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
