import { Link } from "gatsby"
import React from "react"
import "./HeaderLink.scss"

export type HeaderLinkProps = {
  label: string;
  slug: string;
  active: boolean;
  visible?: boolean;
}

const HeaderLink = ({ label, slug, active, visible = true }: HeaderLinkProps) => (
  <div className={`header-link ${active ? "header-link--active" : "header-link--inactive"}`}>
    <div>
      <Link
        tabIndex={visible ? 0 : -1}
        to={slug}
      >
        {label}
      </Link>
    </div>
  </div>
)

export default HeaderLink
