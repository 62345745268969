import { Link } from "gatsby"
import React from "react"
import "./HeaderLogo.scss"

export type HeaderLogoProps = {
  label: string;
}

const HeaderLogo = ({ label }: HeaderLogoProps) => (
  <div className="header-logo">
    <Link to="/">{label}</Link>
  </div>
)

export default HeaderLogo
