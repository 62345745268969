import React from "react"
import "./Header.scss"
import { HeaderLogoProps } from "../HeaderLogo/HeaderLogo"
import { HeaderLinksProps } from "../HeaderLinks/HeaderLinks"
import DesktopHeader from "../DesktopHeader/DesktopHeader"
import MobileHeader from "../MobileHeader/MobileHeader"

export type HeaderProps = {
  headerLogo: HeaderLogoProps;
  headerLinks: HeaderLinksProps;
}

const Header = (props: HeaderProps) => (
  <header className="header">
    <DesktopHeader {...props} />
    <MobileHeader {...props} />
  </header>
)

export default Header
