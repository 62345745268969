import React from "react"
import "./Footer.scss"
import Container from "../Container/Container"
import ScreenReaderOnly from "../ScreenReaderOnly/ScreenReaderOnly"
import KristSamaritanCenterLogoImage from "../KristSamaritanCenterLogoImage"

const Footer = () => (
  <div className="footer">
    <Container theme="gray" padding="slim-section">
      <div className="footer__info">
        <div className="footer__info-column">
          <div className="footer__label">
            You'll find us at
          </div>
          <div>
            16441 Space Center Blvd, Suite C-100<br />
            Houston, Texas, 77058
          </div>
          <div className="footer__social-icons">
            <div className="footer__social-icon">
              <a href="https://www.facebook.com/yaxleyirlen" target="_blank" rel="noopener">
                <ScreenReaderOnly>Facebook</ScreenReaderOnly>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill={'#A656E3'} d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
              </a>
            </div>
          </div>
        </div>
        <div className="footer__info-column">
          <div className="footer__contact-details">
            <div className="footer__label">
              Give Irlen Director a call on
            </div>
            <div className="footer__contact-link">
              <a href="tel:+18327203071">
                +1 832 720 3071
              </a>
            </div>
            <div className="footer__label">
              Send a message
            </div>
            <div className="footer__contact-link">
              <a href="mailto:jyaxley@samaritanhouston.org">
                jyaxley@samaritanhouston.org
              </a>
            </div>
          </div>
        </div>
        <div className="footer__info-column">
          <div className="footer__label">
            For more information:
          </div>
          <div className="footer__partner-icons">
            <div className="footer__partner-icon">
              <div style={{display: 'flex', flexDirection: "column"}}>
                <a href="https://www.irlen.com/" target="_blank" rel="noopener">
                  <img src="https://i.imgur.com/ExsQ6DI.png" alt="Irlen" style={{marginBottom: '0.5rem'}} />
                </a>
                <a href="https://www.irlen.com/" target="_blank" rel="noopener">
                  irlen.com
                </a>
              </div>
            </div>
            <div className="footer__partner-icon">
              <a href="https://www.samaritanhouston.org/irlen-services/" target="_blank" rel="noopener">
                <KristSamaritanCenterLogoImage alt="Carole and Ronald Krist, Samaritan Counseling Center" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <Container theme="accent">
      <div className="footer__copyright">
        Copyright &copy; 2015-{new Date().getFullYear()} - Julie Yaxley, Irlen Clinic, Krist Samaritan Center, Houston. All Rights Reserved.<br />
        Copyright &copy; 1990-{new Date().getFullYear()} by Perceptual Development Corp/Helen Irlen. All Rights Reserved.
      </div>
    </Container>
  </div>
)

export default Footer
