import React, { useEffect, useState } from "react"
import "./MobileHeader.scss"
import { HeaderProps } from "../Header/Header"
import HeaderLogo from "../HeaderLogo/HeaderLogo"
import Hamburger from "../Hamburger/Hamburger"
import HeaderLinks from "../HeaderLinks/HeaderLinks"
import Container from "../Container/Container"

const MobileHeader = ({ headerLogo, headerLinks }: HeaderProps) => {
  const [open, setOpen] = useState(false);

  const onHamburgerClick = () => {
    setOpen(!open);
  }

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : '';
    document.body.style.height = open ? window.innerHeight + 'px' : '';
  })

  return (
    <>
      <div className="mobile-header">
        <Container theme="solid-white">
          <div className="mobile-header__controls">
            <HeaderLogo {...headerLogo} />
            <Hamburger open={open} onHamburgerClick={onHamburgerClick} />
          </div>
        </Container>
        <div className={`mobile-header__items ${open ? 'mobile-header__items--open' : ''}`}>
          <HeaderLinks {...headerLinks} vertical={true} visible={open} />
        </div>
      </div>
      <div className="mobile-header-spacing" />
      <div className={`mobile-header-overlay ${open ? 'mobile-header-overlay--active' : ''}`} onClick={() => setOpen(!open)} />
    </>
  )
}

export default MobileHeader
