import React from "react"
import "./DesktopHeader.scss"
import { HeaderProps } from "../Header/Header"
import HeaderLogo from "../HeaderLogo/HeaderLogo"
import HeaderLinks from "../HeaderLinks/HeaderLinks"
import GlassesImage from "../GlassesImage"
import Container from "../Container/Container"

const DesktopHeader = ({ headerLogo, headerLinks }: HeaderProps) => (
  <div className="desktop-header-container">
    <Container theme="solid-white">
      <div className="desktop-header">
        <HeaderLogo {...headerLogo} />
        <HeaderLinks {...headerLinks} />
        <GlassesImage />
      </div>
    </Container>
  </div>
)

export default DesktopHeader
