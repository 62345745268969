import React from "react"
import "./Container.scss"

export interface ContainerProps extends React.HTMLProps<HTMLDivElement> {
  theme?: 'transparent' | 'solid-white' | 'white' | 'gray' | 'accent';
  padding?: 'section' | 'slim-section' | 'padded' | 'vertical-only' | 'none';
  textAlign?: 'left' | 'center' | 'right';
}

const Container = ({ theme = 'transparent', padding = 'padded', textAlign = 'left', children }: ContainerProps) => (
  <div className={`container container--theme-${theme} container--padding-${padding} container--text-align-${textAlign}`}>
    <div className="container__children">
      {children}
    </div>
  </div>
)

export default Container
