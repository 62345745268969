import React from "react"
import "./ColorPicker.scss"

export type ColorPickerProps = {
  onColorSelect: (color: string) => void;
  label?: string;
}

const colors = [
  {
    value: '#ffffff',
    name: 'white'
  },
  {
    value: '#dce6f2',
    name: 'light blue gray'
  },
  {
    value: '#9ab8fc',
    name: 'blue'
  },
  {
    value: '#a6f8e3',
    name: 'aqua'
  },
  {
    value: '#aaf2a0',
    name: 'green'
  },
  {
    value: '#ffff8f',
    name: 'yellow'
  },
  {
    value: '#ffde75',
    name: 'orange'
  },
  {
    value: '#bf95df',
    name: 'purple'
  },
  {
    value: '#fcd5b5',
    name: 'peach'
  },
  {
    value: '#e3b0af',
    name: 'red brown'
  },
  {
    value: '#d9d9d9',
    name: 'gray'
  }
]

const ColorPicker = ({ onColorSelect, label = 'Color helps people with Irlen Syndrome. Try it!' }: ColorPickerProps) => (
  <div className="color-picker">
    <div className="color-picker__label">
      {label}
    </div>
    <div className="color-picker__controls">
      {colors.map(color => (
        <button key={color.value}
                title={`Change background color to ${color.name}`}
                className="color-picker__control"
                style={{backgroundColor: color.value}}
                onClick={() => onColorSelect(color.value)}>
        </button>
      ))}
    </div>
  </div>
)

export default ColorPicker
