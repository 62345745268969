import React from "react"
import "./Hamburger.scss"

export interface HamburgerProps {
  open: boolean;
  onHamburgerClick: () => void;
}

const Hamburger = ({ open, onHamburgerClick }: HamburgerProps) => (
  <button title="Open navigation menu" className={`hamburger ${open ? 'open' : ''}`} onClick={onHamburgerClick}>
    <span className="hamburger__top-bun" />
    <span className="hamburger__middle-bun" />
    <span className="hamburger__bottom-bun" />
  </button>
)

export default Hamburger
