import React from "react"
import "./HeaderLinks.scss"
import HeaderLink, { HeaderLinkProps } from "../HeaderLink/HeaderLink"

export type HeaderLinksProps = {
  headerLinks: HeaderLinkProps[];
  vertical?: boolean;
  visible?: boolean;
}

const HeaderLinks = ({ headerLinks, vertical = false, visible = true }: HeaderLinksProps) => (
  <div className={`header-links ${vertical ? 'header-links--vertical' : ''}`}>
    {headerLinks.map(headerLink => <HeaderLink key={headerLink.slug} {...headerLink} visible={visible} />)}
  </div>
)

export default HeaderLinks
